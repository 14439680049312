import React, { useCallback, useEffect } from "react";
import BasicCard from "./Card";
import logoImg from "../assets/logo.png";

import TransitionsModal from "./Modal/Modal";
// import "../App.css";
const MainSection = () => {
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [values, setValues] = React.useState({
    name: "",
    lName: "",
    email: "",
    phone: "",
  });
  const submitHandler = useCallback(() => {
    setIsSubmit(true);
  }, []);
  const resetHandler = useCallback(() => {
    setIsSubmit(false);
    setValues({
      name: "",
      lName: "",
      email: "",
      phone: "",
    });
  }, []);
  return (
    <>
      {isSubmit ? (
        <TransitionsModal values={values} resetHandler={resetHandler} />
      ) : null}
      <div className="main__section py-4">
        <div className="d-flex justify-content-center pb-md-4  py-xxl-4 pt-md-5 pt-lg-0">
          <img src={logoImg} className="img" alt="logo" />
        </div>

        <div className="align-items-center container form-height d-flex  justify-content-center ">
          <div className="row main__container pt-5 pt-lg-0">
            <div
              // className={`mb-4 mb-lg-0 main__text__Section${
              //   isSubmit ? "col-12" : "col-md-12 col-lg-6"
              // }`}
              className="col-md-12 col-lg-6  mb-4 mb-lg-0 main__text__Section pt-lg-4 mt-lg-1 pt-md-0 mt-md-0 "
            >
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
              </div>
              <div className="row">
                <div className=" col-md-6 mb-3 mb-md-0">
                  <h3 className=" text__yellow">Ravers</h3>
                  <h6 className="primary__text">
                    Come and join this growing festival, rave and gig community
                    and find all your events all in one place. It’s free to
                    register!
                  </h6>
                </div>
                <div className="col-md-6">
                  <h3 className=" text__yellow">Music Promoters</h3>
                  <h6 className="primary__text">
                    Advertise and promote your music events to our dedicated
                    festival, rave and gig community, all in one place. It’s
                    free to register!
                  </h6>
                </div>
              </div>
              {/* <div className="pe-lg-5">
                  <div className="mb-4">
                    <h3 className="text-justify text__yellow">Ravers</h3>
                    <h6 className="text-justify">
                      Come and join this growing festival, rave and gig
                      community and find all your events all in one place. It’s
                      free to register!
                    </h6>
                  </div>
                  <h3 className="text-justify text__yellow">Music Promoters</h3>
                  <h6 className="text-justify">
                    Advertise and promote your music events to our dedicated
                    festival, rave and gig community, all in one place. It’s
                    free to register!
                  </h6>

                  <div className="d-flex gap-2 align-items-center mt-4 pt-2 flex-wrap">
                    <img src={appleImage} alt="" className="img-width" />
                    <img src={googleImg} alt="" className="img-width" />
                  </div>
                </div> */}
            </div>
            <div
              // className={`${isSubmit ? "col-12" : "col-md-12 col-lg-6"}`}
              className="card-container col-md-12 col-lg-6"
            >
              {/* {!isSubmit && (
                  <BasicCard
                    submitHandler={submitHandler}
                    values={values}
                    setValues={setValues}
                  />
                )} */}
              {/* show always */}
              <BasicCard
                submitHandler={submitHandler}
                values={values}
                setValues={setValues}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainSection;
