import * as React from "react";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";

export default function MinHeightTextarea({
  handleQ,
  qValue,
  name,
  // onChangeHandler,
  value,
}) {
  // console.log("qValue", qValue);
  // const [textAreaVal, setTextAreaVal] = React.useState("");
  const handleTextArea = (e) => {
    // setTextAreaVal(e.target.value);
    // onChangeHandler(e);
    handleQ(e.target.value);
  };

  return (
    <textarea
      className="p-2 rounded-2 textarea"
      rows={5}
      autoFocus
      // aria-label="minimum height"
      value={qValue}
      placeholder=""
      name={name}
      onChange={handleTextArea}
    />
  );
}
