import React from "react";
import Header from "./components/Header";
import "./App.css";
import MainSection from "./components/MainSection";

import TransitionsModal from "./components/Modal/Modal";
const App = () => {
  return (
    <div>
      {/* <div></div> */}
      <MainSection />
    </div>
  );
};

export default App;
