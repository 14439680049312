import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const CheckboxComponent = ({
  fields,
  // onChangeHandler,
  value,
  name,
  keyName,
  handleQ,
  qValue,
}) => {
  // console.log("field", value, fields);
  const [state, setState] = useState(
    fields?.reduce((acc, field) => {
      acc[field.name] = false;
      return acc;
    }, {})
  );
  const filterTrueValues = (obj) => {
    const filteredObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === true) {
        filteredObj[key] = true;
      }
    }
    const filteredArr = Object.keys(filteredObj);
    // console.log("filteredArr", filteredArr);
    return filteredArr;
  };

  const handleChange = (event) => {
    // console.log("state", state);
    let data = { ...state, [event.target.name]: event.target.checked };
    data = filterTrueValues(data);
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    handleQ(data);
    // onChangeHandler(event, name);
  };

  return (
    <FormControl component="fieldset">
      {/* <FormLabel component="legend">Event Categories</FormLabel> */}
      <FormGroup>
        {fields?.map((field) => (
          <FormControlLabel
            key={field.name}
            control={
              <Checkbox
                sx={{
                  color: "#FF9900",
                  "&.Mui-checked": {
                    color: "#FF9900",
                  },
                  "& .MuiSvgIcon-root": { fontSize: 18 },
                  padding: "0 9px",
                }}
                className="text__yellow"
                checked={state[field.name]}
                onChange={handleChange}
                name={field.name}
                disableRipple={true}
                // color="primary"
              />
            }
            label={field.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxComponent;
