import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const root = document.documentElement;
const computedStyle = getComputedStyle(root);

const lightDark = computedStyle.getPropertyValue("--light-dark").trim();
const yellow = computedStyle.getPropertyValue("--yellow-color").trim();
const lightWhite = computedStyle.getPropertyValue("--light-white").trim();

const Style = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    color: "#828393",
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    border: `1px solid ${"#d8d8dd"}`,
    // border: "2px solid #FF9900",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border"]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderRadius: 4,
      border: `2px solid #FF9900`,
    },
  },
}));
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "200px",
    },
  },
};
export default function SelectLabels({
  qValue,
  handleQ,
  // onChangeHandler,
  type,
  name,
}) {
  const [defaultVal, setDefaultVal] = React.useState("");

  const numbers = Array.from({ length: 20 }, (_, i) => i + 1);
  const days = Array.from({ length: 365 }, (_, i) => i + 1);
  const amount = Array.from({ length: 25 }, (_, i) => i + 1);
  const fields1 = [
    { name: "1x", label: "1x " },
    { name: "2x", label: "2x " },
    { name: "3x", label: "3x" },
    { name: "4x", label: "4x" },
    { name: "5x or More", label: "5x or More" },
  ];
  const checkEvents = ["Daily", "Weekly", "Monthly"];
  const checkEventsMenu = checkEvents?.map((value, index) => (
    <MenuItem key={value + index} value={value}>
      {value}
    </MenuItem>
  ));

  const averageMonthMenu = fields1?.map((value, index) => (
    <MenuItem key={value?.name} value={value.name}>
      {value.name}
    </MenuItem>
  ));
  const amountMenuItem = amount.map((number) => (
    <MenuItem key={number} value={`£` + number}>
      £{number}
    </MenuItem>
  ));
  const dayMenuItem = days.map((number) => (
    <MenuItem key={number} value={number}>
      {number}
    </MenuItem>
  ));
  const menuItems = numbers.map((number) => (
    <MenuItem key={number} value={number}>
      {number}
    </MenuItem>
  ));

  // Add the "21+" MenuItem
  menuItems.push(
    <MenuItem key="21+" value="21+">
      21+
    </MenuItem>
  );

  const selectHandler = (e) => {
    console.log("e.target", e.target);
    handleQ(e?.target?.value);
    // onChangeHandler(e);
  };
  // React.useEffect(() => {
  //   let initialVal = "";
  //   switch (type) {
  //     case "manyEvent":
  //       initialVal = numbers[0];
  //       break;
  //     case "startPromotion":
  //       initialVal = days[0];
  //       break;
  //     case "amount":
  //       initialVal = "£" + amount[0];
  //       break;
  //     case "averageMonth":
  //       initialVal = fields1[0]?.name;
  //       break;
  //     case "checkEvents":
  //       initialVal = checkEvents[0];
  //       break;
  //     default:
  //       initialVal = "";
  //   }
  //   setDefaultVal(initialVal);
  // }, [type]);
  // console.log("qValue", qValue);
  return (
    <div>
      <FormControl sx={{ m: 0, minWidth: "100%" }}>
        <Select
          MenuProps={MenuProps}
          className="p-1 mui__select"
          name={name}
          // defaultValue={defaultVal}
          value={qValue}
          // value={qValue || defaultVal}
          onChange={selectHandler}
          displayEmpty
          input={<Style />}
        >
          <MenuItem value="">Select</MenuItem>
          {type === "manyEvent" && menuItems}
          {type === "startPromotion" && dayMenuItem}
          {type === "amount" && amountMenuItem}
          {type === "averageMonth" && averageMonthMenu}
          {type === "checkEvents" && checkEventsMenu}
        </Select>
      </FormControl>
    </div>
  );
}
