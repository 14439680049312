export let data = {
  data: [
    {
      question: "Are you a Music Events Promoter?",
      answers: [
        { option: "Yes", correct: false },
        { option: "No", correct: false },
      ],
      type: "radio",
      questionType: 2,
    },
    {
      question: "How many events do you host per annum?",
      answers: [{ option: null, correct: true }],
      type: "select",
      questionType: 4,
    },
    {
      question: "What type of events do you host?",
      answers: [
        { option: "Day Parties", correct: false },
        { option: "Night Clubbing", correct: false },
        { option: "Festivals", correct: false },
        { option: "Other Events", correct: false },
      ],
      type: "checkbox",
      questionType: 1,
    },
    {
      question:
        "How many days before your events would you start to promote your event?",
      answers: [{ option: null, correct: true }],
      type: "select",
      questionType: 3,
    },
    {
      question:
        "How much would you pay as a daily booking fee to promote your event on a platform dedicated to a music audience of partygoers and ravers?",
      answers: [{ option: null, correct: true }],
      type: "select",
      questionType: 8,
    },
    {
      question:
        "Would you be happy to give away, 2 free tickets and prizes as a minimum, per event to help generate additional interest for your events?",
      answers: [
        { option: "Yes", correct: false },
        { option: "No", correct: false },
      ],
      type: "radio",
      questionType: 2,
    },
    {
      question:
        "Would you be keen to work with a credit system where viewers can earn and redeem their credits for liking, sharing and viewing posts. The credit could be used for tickets to one of your events?",
      answers: [
        { option: "Yes", correct: false },
        { option: "No", correct: false },
      ],
      type: "radio",
      questionType: 2,
    },
    {
      question:
        "What additional tools would you like to see on this platform besides, adding events, creating event stories and competitions?",
      answers: [{ option: null, correct: true }],
      type: "select",
      questionType: 7,
    },
  ],
  name: "",
  email: "",
  last_name: "",
  phone: "",
};

export const data1 = {
  data: [
    {
      question: "Are you a Music Events Promoter?",
      answers: [
        { option: "Yes", correct: false },
        { option: "No", correct: false },
      ],
      type: "radio",
      questionType: 2,
    },

    {
      question:
        "Is finding the music events you’d like to attend all in one place something you would like to see?",
      answers: [
        { option: "Yes", correct: false },
        { option: "No", correct: false },
      ],
      type: "radio",
      questionType: 2,
    },
    {
      question: "How often do you look to go out on average per month?",
      answers: [{ option: null, correct: false }],
      type: "select",
      questionType: 5,
    },
    {
      question: "What type of events are you interested in going to?",
      answers: [
        { option: "Day Parties", correct: false },
        { option: "Night Clubbing", correct: false },
        { option: "Festivals", correct: false },
        { option: "Other Events", correct: false },
      ],
      type: "checkbox",
      questionType: 1,
    },
    {
      question:
        "How often do you go online to social platforms to look for somewhere to go and check events?",
      answers: [
        { option: "Daily", correct: false },
        { option: "Weekly", correct: false },
        { option: "Monthly", correct: false },
      ],
      type: "checkbox",
      questionType: 2,
    },
    {
      question:
        "Would you be keen to work with a credit system where viewers can earn and redeem their credits for liking, sharing and viewing posts. The credit could be used for tickets to one of your events?",
      answers: [
        { option: "Yes", correct: false },
        { option: "No", correct: false },
      ],
      type: "radio",
      questionType: 2,
    },
    {
      question:
        "Would you also like to win free tickets and prizes to competitions?",
      answers: [
        { option: "Yes", correct: false },
        { option: "No", correct: false },
      ],
      type: "radio",
      questionType: 2,
    },
    {
      question:
        "What additional features would you like to see apart from, events, creating/viewing event stories and entering competitions?",
      answers: [{ option: null, correct: true }],
      type: "select",
      questionType: 7,
    },
  ],
  name: "",
  email: "",
  last_name: "",
  phone: "",
};
