import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    // color: "#FF9900",
    color: "#828393 ",
  },
  ".Mui-checked": checked && {
    color: "#FF9900",
    // color: "#828393 ",
  },
}));

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

MyFormControlLabel.propTypes = {
  /**
   * The value of the component.
   */
  value: PropTypes.any,
};

export default function RadioComponent({
  handleQ,
  // onChangeHandler,
  name,
  value,
}) {
  const handleClick = (e) => {
    handleQ(e.target.value);
    // onChangeHandler(e, name);
  };
  return (
    <RadioGroup name="use-radio-group" defaultValue="first" className="">
      <MyFormControlLabel
        value={"true"}
        name={name}
        label="Yes"
        control={
          <Radio
            disableRipple={true}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 20,
              },
              "&.Mui-checked": {
                color: "#FF9900",
              },
              padding: "0px 9px",
            }}
          />
        }
        onClick={(e) => handleClick(e)}
      />
      <MyFormControlLabel
        value={"false"}
        name={name}
        label="No"
        control={
          <Radio
            disableRipple={true}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 20,
              },
              "&.Mui-checked": {
                color: "#FF9900",
              },
              padding: "0px 9px",
            }}
          />
        }
        onClick={(e) => handleClick(e)}
      />
    </RadioGroup>
  );
}
