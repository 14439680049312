import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Height } from "@mui/icons-material";
import SelectLabels from "../OptionComponent/Option";
import CheckboxComponent from "../OptionComponent/CheckboxComponent";
import RadioComponent from "../OptionComponent/RadioComponent";
import MinHeightTextarea from "../OptionComponent/TextArea";
import CloseIcon from "@mui/icons-material/Close";
import { data1, data } from "./quize";
import axios from "axios";
import { IconButton } from "@mui/material";
import { ClipLoader } from "react-spinners";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%", // Set width to 80% of the viewport width
  maxWidth: 600, // Set maximum width to 600px
  bgcolor: "background.paper",
  border: "2px solid ",
  boxShadow: 24,
  p: 2,
};

export default function TransitionsModal({ values, resetHandler }) {
  const [isContinue, setIsContinue] = React.useState(false);
  const [question1, setQuestion1] = React.useState("");
  const [question2, setQuestion2] = React.useState("");
  const [question3, setQuestion3] = React.useState("");
  const [question4, setQuestion4] = React.useState("");
  const [question5, setQuestion5] = React.useState("");
  const [question6, setQuestion6] = React.useState("");
  const [question7, setQuestion7] = React.useState("");
  const [question8, setQuestion8] = React.useState("");
  const [reqiredErr, setRequireErr] = React.useState(null);
  const [finalData, setFinalData] = React.useState({});
  const [isResponseSend, setIsResponseSend] = React.useState(null);
  const [isDisabled, setIsDisable] = React.useState(false);
  const handleQuestion1 = (value) => {
    setQuestion1(value);
    if (counter === 0) setCounter(1);
  };

  const handleQuestion2 = (value) => {
    console.log("value", value);
    setQuestion2(value);
    if (counter === 1) setCounter(2);
  };

  const handleQuestion3 = (value) => {
    setQuestion3(value);
    if (counter === 2) setCounter(3);
  };

  const handleQuestion4 = (value) => {
    setQuestion4(value);
    if (counter === 3) setCounter(4);
  };

  const handleQuestion5 = (value) => {
    setQuestion5(value);
    if (counter === 4) setCounter(5);
  };

  const handleQuestion6 = (value) => {
    setQuestion6(value);
    if (counter === 5) setCounter(6);
  };

  const handleQuestion7 = (value) => {
    setQuestion7(value);
    if (counter === 6) setCounter(7);
  };

  const handleQuestion8 = (value) => {
    setQuestion8(value);
    if (counter === 7) setCounter(8);
  };
  React.useEffect(() => {
    // console.log("question1", question1);
    if (question1 === "true") {
      setQuestion2("");
      setQuestion3("");
      setQuestion4("");
      setQuestion5("");
      setQuestion6("");
      setQuestion7("");
      setQuestion8("");
      setCounter(1);
    }
    if (question1 === "false") {
      setQuestion2("");
      setQuestion3("");
      setQuestion4("");
      setQuestion5("");
      setQuestion6("");
      setQuestion7("");
      setQuestion8("");
      setCounter(1);
    }
  }, [question1]);
  const [open, setOpen] = React.useState(true);
  const [counter, setCounter] = React.useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fields = [
    { name: "dayParties", label: "Day Parties" },
    { name: "nightClubbing", label: "Night Clubbing" },
    { name: "festivals", label: "Festivals" },
    { name: "otherEvents", label: "Other Events" },
  ];
  React.useEffect(() => {
    setRequireErr(null);
  }, [
    question1,
    question2,
    question3,
    question4,
    question5,
    question6,
    question7,
    question8,
  ]);
  function populateAnswers(answersArray) {
    let selectedData = answersArray[0] === "true" ? data?.data : data1?.data;

    for (let i = 0; i < selectedData.length; i++) {
      let type = selectedData[i].type;
      let answerValue = answersArray[i];
      if (answerValue) {
        if (type === "radio") {
          selectedData[i].answers = selectedData[i].answers.map((answer) => ({
            option: answer.option,
            correct:
              (answer.option === "Yes" && answerValue === "true") ||
              (answer.option === "No" && answerValue !== "true"),
          }));
        } else if (type === "checkbox" && answerValue?.length) {
          if (!Array.isArray(answerValue)) {
            // If answerValue is not an array, make it an array
            answerValue = [answerValue];
          }
          selectedData[i].answers = selectedData[i].answers.map((answer) => ({
            option: answer.option,
            correct: answerValue.some((val) => {
              let formattedVal = val
                .replace(/([A-Z])/g, " $1")
                .replace(/^./, function (str) {
                  return str.toUpperCase();
                })
                .trim();
              return formattedVal === answer.option;
            }),
          }));
        } else if (type === "select") {
          let numericValue = null;
          if (selectedData[i].questionType !== 7) {
            numericValue = extractNumericValue(answerValue);
          }
          // console.log("answerValue", answerValue);
          selectedData[i].answers = [
            {
              option: numericValue !== null ? numericValue : answerValue,
              correct: true,
            },
          ];
        }
      }
    }

    return selectedData;
  }
  function validateArray(arr) {
    const errors = [];

    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];

      if (Array.isArray(item)) {
        if (item.length === 0) {
          errors.push(`Question ${i + 1}: Answer array is empty`);
        }
      } else if (typeof item === "string" || typeof item === "number") {
        if (item === "" || item === null || item === undefined) {
          errors.push(`Question ${i + 1}: Answer is empty or invalid`);
        }
      } else {
        errors.push(`Question ${i + 1}: Answer is of an invalid type`);
      }
    }

    if (errors.length > 0) {
      return {
        isValid: false,
        message: `All questions are required.`,
      };
    }

    return {
      isValid: true,
      message: "",
    };
  }

  function extractNumericValue(str) {
    // Regular expression to match any number
    const regex = /\d+(\.\d+)?/;
    // Extract numeric value using regular expression
    if (typeof str === "string") {
      const match = str.match(regex);
      // If match is found, return the numeric value, otherwise return null
      return match ? parseFloat(match[0]) : null;
    } else {
      return null;
    }
  }

  async function submitHandler() {
    setIsDisable(true);
    let ansArray = [
      question1,
      question2,
      question3,
      question4,
      question5,
      question6,
      question7,
      question8,
    ];
    // console.log("ansArray", ansArray);

    let populatedData = populateAnswers(ansArray);
    // console.log("populatedData", populatedData);
    const validResp = validateArray(ansArray);
    // console.log("validateArray resp", validResp);
    setFinalData(populatedData);

    // let data = {
    //   first_name: values?.name,
    //   email: values?.email,
    //   last_name: values?.lName,
    //   phone:
    //     values?.phone.substring(1).length > 6 ? values?.phone.substring(1) : "",
    //   data: finalData,
    // };
    // let result = validateData(data);
    // console.log("result", result);
    // const isEmpty = Object.keys(result).length === 0;
    const isEmpty = validResp?.isValid;
    if (isEmpty) {
      try {
        let resp = await axios.post(
          "https://adevent.co.uk/backend/api/survey-api",
          {
            first_name: values?.name,
            email: values?.email?.toLowerCase(),
            last_name: values?.lName,
            phone:
              values?.phone.substring(1).length > 5
                ? values?.phone.substring(1).replace(/\s+/g, "")
                : "",
            data: JSON.stringify(populatedData),
            userType: question1 === "true" ? 1 : 2,
          }
        );
        if (resp?.status === 200 && resp?.data?.status) {
          setIsResponseSend(resp?.data?.status);
        }
        // console.log("resp", resp?.data?.status);
        if (resp?.status === 200 && resp?.data.status === false) {
          // alert("klhl");
          setRequireErr(resp?.data?.message);
        }
        setIsDisable(false);
        // console.log("resp", resp);
      } catch (error) {
        console.log("error", error);
        setRequireErr("Something went Wrong");
        setIsDisable(false);
      }
    } else {
      setIsDisable(false);
      setRequireErr(validResp?.message);
    }
    // console.log("validated data", data);
  }

  return (
    <div className="modal__container">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        InputProps={{ disableanimation: true }}
        // disableRipple={true}
        className="modal__container"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={null}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            // timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {isResponseSend ? (
              <>
                <Typography
                  className="fs-5 text-center  my-3  text__yellow"
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ fontWeight: "bold", mb: "5px" }}
                >
                  {/* Your responses to the questions have been submitted */}
                  Thank you for participating
                </Typography>
                {/* <Typography
                  className="fs-6  text-center  my-2 text__yellow"
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ fontWeight: "bold", mb: "5px" }}
                >
                  Thank you for participating
                </Typography> */}
                <Typography
                  className="fs-6  text-center  my-2 text__yellow"
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ fontWeight: "bold", mb: "5px" }}
                >
                  Please check your email
                </Typography>
                <Typography
                  variant="body1"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className="mt-5"
                >
                  <Button
                    disableRipple={true}
                    disabled={isDisabled}
                    onClick={() => window.location.reload()}
                    className="button px-4 py-2 btn__modal fw-bold "
                  >
                    Close
                  </Button>
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  className="text-uppercase text__yellow text-decoration-underline "
                  id="transition-modal-title"
                  variant="h4"
                  component="h3"
                  textAlign={"center"}
                  mb={3}
                >
                  survey
                </Typography>
                {isContinue ? (
                  <>
                    {counter >= 0 && (
                      <>
                        <Typography
                          className="fs-6"
                          id="transition-modal-title"
                          variant="h6"
                          component="h2"
                          sx={{ fontWeight: "bold", mb: "5px" }}
                        >
                          Q1. Are you a Music Events Promoter?
                          <span className="text-danger fw-bold">*</span>
                        </Typography>
                        <Typography
                          id="transition-modal-description"
                          sx={{ mb: 2 }}
                        >
                          {/* <Button variant="outlined" className="m-2">
                    Yes
                  </Button>
                  <Button variant="outlined" className="m-2">
                    No
                  </Button> */}
                          <RadioComponent
                            handleQ={handleQuestion1}
                            // onChangeHandler={onChangeHandler}
                            name={"isPromoter"}
                            // value={surveyAns.isPromoter}
                          />
                        </Typography>
                        {/* <hr /> */}
                      </>
                    )}
                    {question1 === "true" ? (
                      <>
                        {counter >= 1 && (
                          <>
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              component="h2"
                              className="fs-6"
                              sx={{ fontWeight: "bold", mb: "5px" }}
                            >
                              Q2. How many events do you host per annum?
                              <span className="text-danger fw-bold">*</span>
                            </Typography>
                            <Typography
                              id="transition-modal-description"
                              sx={{ mb: 2 }}
                            >
                              <SelectLabels
                                type={"manyEvent"}
                                name="eventsPerAnnum"
                                handleQ={handleQuestion2}
                                qValue={question2}
                                // onChangeHandler={onChangeHandler}
                                // value={surveyAns.eventsPerAnnum}
                              />
                            </Typography>
                          </>
                        )}
                        {counter >= 2 && (
                          <>
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              component="h2"
                              className="fs-6"
                              sx={{ fontWeight: "bold", mb: "5px" }}
                            >
                              Q3. What type of events do you host?
                              <span className="text-danger fw-bold">*</span>
                            </Typography>
                            <Typography
                              id="transition-modal-description"
                              sx={{ mb: 2 }}
                            >
                              <CheckboxComponent
                                qValue={question3}
                                handleQ={handleQuestion3}
                                fields={fields}
                                // onChangeHandler={onChangeHandler}
                                // value={surveyAns.eventsType}
                                name="eventsType"
                              />
                            </Typography>
                          </>
                        )}
                        {counter >= 3 && (
                          <>
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              component="h2"
                              className="fs-6"
                              sx={{ fontWeight: "bold", mb: "5px" }}
                            >
                              Q4. How many days before your events would you
                              start to promote your event?
                              <span className="text-danger fw-bold">*</span>
                            </Typography>
                            <Typography
                              id="transition-modal-description"
                              sx={{ mb: 2 }}
                            >
                              <SelectLabels
                                qValue={question4}
                                handleQ={handleQuestion4}
                                type={"startPromotion"}
                                name="startPromotion"
                                // onChangeHandler={onChangeHandler}
                                // value={surveyAns.startPromotion}
                              />
                            </Typography>
                          </>
                        )}
                        {counter >= 4 && (
                          <>
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              component="h2"
                              className="fs-6"
                              sx={{ fontWeight: "bold", mb: "5px" }}
                            >
                              Q5.How much would you pay as a daily booking fee
                              to promote your event on a platform dedicated to a
                              music audience of partygoers and ravers?
                              <span className="text-danger fw-bold">*</span>
                            </Typography>
                            <Typography
                              id="transition-modal-description"
                              sx={{ mb: 2 }}
                            >
                              <SelectLabels
                                qValue={question5}
                                handleQ={handleQuestion5}
                                type={"amount"}
                                name="dailyBookingFee"
                                // onChangeHandler={onChangeHandler}
                                // value={surveyAns.dailyBookingFee}
                              />
                            </Typography>
                          </>
                        )}
                        {counter >= 5 && (
                          <>
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              component="h2"
                              className="fs-6"
                              sx={{ fontWeight: "bold", mb: "5px" }}
                            >
                              Q6. Would you be happy to give away, 2 free
                              tickets and prizes as a minimum, per event to help
                              generate additional interest for your events?
                              <span className="text-danger fw-bold">*</span>
                            </Typography>
                            <Typography
                              id="transition-modal-description"
                              sx={{ mb: 2 }}
                            >
                              <RadioComponent
                                qValue={question6}
                                handleQ={handleQuestion6}
                                // onChangeHandler={onChangeHandler}
                                name={"giveAway"}
                              />
                            </Typography>
                          </>
                        )}
                        {counter >= 6 && (
                          <>
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              component="h2"
                              className="fs-6"
                              sx={{ fontWeight: "bold", mb: "5px" }}
                            >
                              Q7. Would you be keen to work with a credit system
                              where viewers can earn and redeem their credits
                              for liking, sharing and viewing posts. The credit
                              could be used for tickets to one of your events?
                              <span className="text-danger fw-bold">*</span>
                            </Typography>
                            <Typography
                              id="transition-modal-description"
                              sx={{ mb: 2 }}
                            >
                              <RadioComponent
                                qValue={question7}
                                handleQ={handleQuestion7}
                                // onChangeHandler={onChangeHandler}
                                name="creditSystem"
                              />
                            </Typography>
                          </>
                        )}
                        {counter >= 7 && (
                          <>
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              component="h2"
                              className="fs-6"
                              sx={{ fontWeight: "bold", mb: "5px" }}
                            >
                              Q8. What additional tools would you like to see on
                              this platform besides, adding events, creating
                              event stories and competitions?
                              <span className="text-danger fw-bold">*</span>
                            </Typography>
                            <Typography
                              id="transition-modal-description"
                              sx={{ mb: 2 }}
                            >
                              <MinHeightTextarea
                                qValue={question8}
                                handleQ={handleQuestion8}
                                // onChangeHandler={onChangeHandler}
                                name="additionalTools"
                                // value={surveyAns.additionalTools}
                              />
                            </Typography>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {counter >= 1 && (
                          <>
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              component="h2"
                              className="fs-6"
                              sx={{ fontWeight: "bold", mb: "5px" }}
                            >
                              Q2. Is finding the music events you’d like to
                              attend all in one place something you would like
                              to see?
                              <span className="text-danger fw-bold">*</span>
                            </Typography>
                            <Typography
                              id="transition-modal-description"
                              sx={{ mb: 2 }}
                            >
                              <RadioComponent
                                // onChangeHandler={onChangeHandler}
                                name={""}
                                qValue={question2}
                                handleQ={handleQuestion2}
                              />
                            </Typography>
                          </>
                        )}
                        {counter >= 2 && (
                          <>
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              component="h2"
                              className="fs-6"
                              sx={{ fontWeight: "bold", mb: "5px" }}
                            >
                              Q3. How often do you look to go out out on average
                              per month?
                              <span className="text-danger fw-bold">*</span>
                            </Typography>
                            <Typography
                              id="transition-modal-description"
                              sx={{ mb: 2 }}
                            >
                              <SelectLabels
                                // onChangeHandler={onChangeHandler}
                                qValue={question3}
                                handleQ={handleQuestion3}
                                type={"averageMonth"}
                              />
                            </Typography>
                          </>
                        )}
                        {counter >= 3 && (
                          <>
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              component="h2"
                              className="fs-6"
                              sx={{ fontWeight: "bold", mb: "5px" }}
                            >
                              Q4. What type of events are you interested in
                              going to?
                              <span className="text-danger fw-bold">*</span>
                            </Typography>
                            <Typography
                              id="transition-modal-description"
                              sx={{ mb: 2 }}
                            >
                              <CheckboxComponent
                                // onChangeHandler={onChangeHandler}
                                qValue={question4}
                                handleQ={handleQuestion4}
                                fields={fields}
                              />
                            </Typography>
                          </>
                        )}
                        {counter >= 4 && (
                          <>
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              component="h2"
                              className="fs-6"
                              sx={{ fontWeight: "bold", mb: "5px" }}
                            >
                              Q5. How often do you go online to social platforms
                              to look for somewhere to go and check events?
                              <span className="text-danger fw-bold">*</span>
                            </Typography>
                            <Typography
                              id="transition-modal-description"
                              sx={{ mb: 2 }}
                            >
                              <SelectLabels
                                type={"checkEvents"}
                                qValue={question5}
                                // onChangeHandler={onChangeHandler}
                                handleQ={handleQuestion5}
                              />
                            </Typography>
                          </>
                        )}

                        {counter >= 5 && (
                          <>
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              component="h2"
                              className="fs-6"
                              sx={{ fontWeight: "bold", mb: "5px" }}
                            >
                              Q6. Would you be keen to work with a credit system
                              where viewers can earn and redeem their credits
                              for liking, sharing and viewing posts. The credit
                              could be used for tickets to one of your events?
                              <span className="text-danger fw-bold">*</span>
                            </Typography>
                            <Typography
                              id="transition-modal-description"
                              sx={{ mb: 2 }}
                            >
                              <RadioComponent
                                qValue={question6}
                                // onChangeHandler={onChangeHandler}
                                handleQ={handleQuestion6}
                              />
                            </Typography>
                          </>
                        )}
                        {counter >= 6 && (
                          <>
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              component="h2"
                              className="fs-6"
                              sx={{ fontWeight: "bold", mb: "5px" }}
                            >
                              Q7. Would you also like to win free tickets and
                              prizes to competitions?
                              <span className="text-danger fw-bold">*</span>
                            </Typography>
                            <Typography
                              id="transition-modal-description"
                              sx={{ mb: 2 }}
                            >
                              <RadioComponent
                                qValue={question7}
                                // onChangeHandler={onChangeHandler}
                                handleQ={handleQuestion7}
                              />
                            </Typography>
                          </>
                        )}
                        {counter >= 7 && (
                          <>
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              component="h2"
                              className="fs-6"
                              sx={{ fontWeight: "bold", mb: "5px" }}
                            >
                              Q8. What additional features would you like to see
                              apart from, events, creating/viewing event stories
                              and entering competitions?
                              <span className="text-danger fw-bold">*</span>
                            </Typography>
                            <Typography
                              id="transition-modal-description"
                              sx={{ mb: 2 }}
                            >
                              <MinHeightTextarea
                                qValue={question8}
                                // onChangeHandler={onChangeHandler}
                                handleQ={handleQuestion8}
                              />
                            </Typography>
                          </>
                        )}
                      </>
                    )}
                    <Typography
                      id="error-field"
                      sx={{ mb: 2 }}
                      className="text-danger text-capitalize"
                    >
                      {counter === 8 && reqiredErr}
                    </Typography>

                    {/* <Typography id="transition-modal-description" sx={{ mb:2 }}>
              <Button onClick={handlePrevious}> pre</Button>
              <Button onClick={handleNext}>
                {" "}
                {counter === 7 ? "Submit" : "next"}
              </Button>
            </Typography> */}
                    {counter === 8 && (
                      <Typography
                        id="transition-modal-description"
                        className="text-center w-100"
                        sx={{ mb: 2 }}
                      >
                        <Button
                          disableRipple={true}
                          disabled={isDisabled}
                          // onClick={() => setOpen(false)}
                          onClick={submitHandler}
                          className="button fw-bold px-4 py-2 btn__modal w-100"
                        >
                          {isDisabled ? (
                            <ClipLoader
                              loading={isDisabled ? true : false}
                              cssOverride={{ width: "25px", height: "25px" }}
                              color="#fff"
                            />
                          ) : (
                            "COMPLETED"
                          )}
                        </Button>
                      </Typography>
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      className="fs-6"
                      id="transition-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{ fontWeight: "bold", mb: "15px", color: "#ff9900" }}
                    >
                      Can you please complete a few short questions so we can
                      better understand expectations?
                    </Typography>
                    <Typography
                      id="transition-modal-description"
                      className="text-center w-100"
                      sx={{ mb: 2 }}
                    >
                      {/* <Button
                        disableRipple={true}
                        className="button fw-bold px-4 py-2 btn__modal w-100"
                        onClick={() => setIsContinue(true)}
                      >
                        Continue
                      </Button> */}
                      {/* <Button> */}
                      <ArrowCircleRightIcon
                        className="text__yellow"
                        onClick={() => setIsContinue(true)}
                        sx={{
                          fontSize: "50px",
                          cursor: "pointer",
                        }}
                      />
                      {/* </Button> */}
                      {/* <ArrowForwardIcon className="text__yellow" /> */}
                      {/* <Button
                        disableRipple={true}
                        disabled={isDisabled}
                        onClick={() => window.location.reload()}
                        className="button px-4 py-2 btn__modal fw-bold "
                      >
                        Close
                      </Button> */}
                    </Typography>
                  </>
                )}
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
// old code for function
/*

let ansArray;
  React.useEffect(() => {
    ansArray = [
      question1,
      question2,
      question3,
      question4,
      question5,
      question6,
      question7,
      question8,
    ];
    console.log("ansArray", ansArray);
    // Function to convert boolean values to "Yes"/"No"
    // const transformAnswer = (answer) => {
    //   if (typeof answer === "string") {
    //     if (answer === "true") return "Yes";
    //     if (answer === "false") return "No";
    //     return answer;
    //   }

    //   if (typeof answer === "boolean") {
    //     return answer ? "Yes" : "No";
    //   }

    // if (typeof answer === "object" && answer !== null) {
    //   const transformedObject = {};
    //   for (const key in answer) {
    //     if (answer.hasOwnProperty(key)) {
    //       transformedObject[key] = transformAnswer(answer[key]);
    //     }
    //   }
    //   return transformedObject;
    // }

    // return answer;
    // };

    // Function to transform all answers in the array
    // const transformAnswersArray = (answersArray) => {
    //   return answersArray.map((answer) => transformAnswer(answer));
    // };
    function populateAnswers(answersArray) {
      let selectedData = answersArray[0] === "true" ? data?.data : data1?.data;
      console.log("selectedData", selectedData);
      for (let i = 0; i < selectedData.length; i++) {
        let type = selectedData[i].type;
        let answerValue = answersArray[i];

        if (answerValue?.length) {
          if (type === "radio") {
            selectedData[i].answers = selectedData[i].answers.map((answer) => ({
              option: answer.option,
              correct:
                (answer.option === "Yes" && answerValue === "true") ||
                (answer.option === "No" && answerValue !== "true"),
            }));
          } else if (type === "checkbox" && answerValue?.length) {
            if (!Array.isArray(answerValue)) {
              // If answerValue is not an array, make it an array
              answerValue = [answerValue];
            }
            selectedData[i].answers = selectedData[i].answers.map((answer) => ({
              option: answer.option,
              correct: answerValue.some((val) => {
                let formattedVal = val
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, function (str) {
                    return str.toUpperCase();
                  })
                  .trim();
                return formattedVal === answer.option;
              }),
            }));
          } else if (type === "select") {
            let numericValue = extractNumericValue(answerValue);
            selectedData[i].answers = [
              {
                option: numericValue !== null ? numericValue : answerValue,
                correct: true,
              },
            ];
          }
        }
      }

      return selectedData;
    }
    function extractNumericValue(str) {
      // Regular expression to match any number
      const regex = /\d+(\.\d+)?/;
      // Extract numeric value using regular expression
      const match = str.match(regex);
      // If match is found, return the numeric value, otherwise return null
      return match ? parseFloat(match[0]) : null;
    }
    let populatedData = populateAnswers(ansArray);
    console.log("populatedData", populatedData);

    setFinalData(populatedData);
    setRequireErr(null);
  }, [
    question1,
    question2,
    question3,
    question4,
    question5,
    question6,
    question7,
    question8,
  ]);

 */
