import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

// Define the styled component outside the functional component
const CssTextField = styled(TextField)(({ theme, iserr }) => ({
  "& label.Mui-focused": {
    color: "#FFF !important",
  },
  "& label": {
    color: iserr ? "#dc3545 !important" : "#fff !important",
  },
  "& .MuiOutlinedInput-root": {
    color: "#FFF",
    "& fieldset": {
      borderColor: "#FFF",
    },
    "&:hover fieldset": {
      borderColor: "#ff9900",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ff9900",
    },
  },
}));

const ReusableTextField = ({
  type,
  label,
  value,
  onChange,
  id,
  helperText,
  size = "small",
}) => {
  return (
    <div>
      <CssTextField
        type={type}
        error={!!helperText}
        iserr={helperText}
        label={label}
        id={id}
        value={value}
        size={size}
        onChange={(e) => onChange(e.target.value)}
        variant="outlined"
      />
    </div>
  );
};

export default ReusableTextField;
