import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import HorizontalLinearAlternativeLabelStepper from "./Stepper";
import { TextField } from "@mui/material";
import ReusableTextField from "./TextField";
import PasswordInput from "./PasswordInput";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { makeStyles } from "@mui/styles";
// import SliderNext from "./Slider/Slider";

const useStyles = makeStyles({
  root: { backgroundColor: "#ffffff5c", color: "red" },
});

export default function BasicCard({ submitHandler, values, setValues }) {
  const classes = useStyles();
  const [errmsg, setErrMsg] = React.useState({
    nameErrMsg: "",
    lNameErrMsg: "",
    emailErrMsg: "",
    phoneErrMsg: "",
  });

  const handleChange = (field, value, set) => {
    set((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
    // handleSubmit();
  };

  const [stepNumber, setStepNumber] = React.useState(0);

  function isValidEmail(email) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }
  const handleSubmit = () => {
    const isPhoneValid = matchIsValidTel(values?.phone);
    // console.log("isPhoneValid", isPhoneValid);
    const mailValid = isValidEmail(values?.email);
    let newErrMsg = {};
    let errorCheck = 0;

    const strippedValue = values?.phone?.replace(/^\+\d+/, "").trim();
    if (values.name === "") {
      newErrMsg = { ...newErrMsg, nameErrMsg: "Please Enter Name" };
      errorCheck = 1;
    }
    if (values.lName === "") {
      newErrMsg = { ...newErrMsg, lNameErrMsg: "Please Enter Last Name" };
      errorCheck = 1;
    }
    if (values.email === "") {
      newErrMsg = { ...newErrMsg, emailErrMsg: "Please Enter valid Mail" };
      errorCheck = 1;
    }
    if (mailValid === false) {
      newErrMsg = { ...newErrMsg, emailErrMsg: "Please Enter valid Mail" };
      errorCheck = 1;
    }

    // optional phone number
    if (strippedValue) {
      if (!isPhoneValid) {
        newErrMsg = { ...newErrMsg, phoneErrMsg: "Please Enter valid Phone" };
        errorCheck = 1;
      }
    }
    setErrMsg(newErrMsg);
    if (errorCheck == 1) {
      return;
    } else {
      setStepNumber(stepNumber + 1);
      submitHandler();
    }
  };
  function btnSubmit() {
    if (Object.keys(errmsg).length === 0) {
      setStepNumber(stepNumber + 1);
      submitHandler();
    } else {
      handleSubmit();
    }
  }
  return (
    <>
      <Card
        // disableRipple={true}
        sx={{ minWidth: 275, background: "#ffffff5c" }}
        className="main__card__body py-3"
        // classes={classes.root}
      >
        <CardContent>
          <Typography
            variant="h5"
            component="div"
            className="text-center text__yellow"
          >
            {/* <HorizontalLinearAlternativeLabelStepper stepNumber={stepNumber} /> */}
            Please Provide Your Details
          </Typography>
          <Typography component="div" className="mt-4 pt-3 row ">
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  marginY: 2,
                  marginX: 0,
                  width: "100%",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <div className=" row">
                {/* {stepNumber === 0 && renderFieldsForStep()} */}

                {/* <div className="col-md-6 mb-4 col-12">
                <MuiTelInput
                  sx={{ width: "100%", m: 2 }}
                  size="small"
                  value={phone}
                  onChange={handlePhoneChange}
                />
              </div> */}
              </div>
              <div className="row">
                {stepNumber === 0 && (
                  <>
                    <div className="col-md-6  col-12">
                      <ReusableTextField
                        helperText={errmsg.nameErrMsg}
                        type={"text"}
                        label={"Name*"}
                        id={"name"}
                        value={values.name}
                        onChange={(value) =>
                          handleChange("name", value, setValues)
                        }
                      />
                    </div>
                    <div className="col-md-6  col-12">
                      <ReusableTextField
                        helperText={errmsg.lNameErrMsg}
                        type={"text"}
                        label={"Last Name*"}
                        id={"lName-input"}
                        value={values.lName}
                        onChange={(value) =>
                          handleChange("lName", value, setValues)
                        }
                      />
                    </div>
                    <div className="col-md-6  col-12">
                      <ReusableTextField
                        helperText={errmsg.emailErrMsg}
                        type={"email"}
                        label={"Email*"}
                        id={"email-input"}
                        value={values.email}
                        onChange={(value) =>
                          handleChange("email", value, setValues)
                        }
                      />
                    </div>
                    <div className="col-md-6  col-12">
                      <MuiTelInput
                        sx={{
                          "& label.Mui-focused": {
                            color: "#FFF",
                          },
                          "& label": {
                            color: "#FFF",
                          },
                          "& .MuiOutlinedInput-root": {
                            color: "#fff",
                            "& fieldset": {
                              borderColor: "#FFF",
                            },
                            "&:hover fieldset": {
                              borderColor: "#ff9900",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#ff9900",
                            },
                          },
                          width: "100%",
                          m: 2,
                        }}
                        // disableRipple={true}
                        forceCallingCode
                        error={errmsg.phoneErrMsg ? true : false}
                        // helperText={errmsg.phoneErrMsg}
                        defaultCountry="GB"
                        size="small"
                        value={values.phone}
                        label="Mobile (Optional)"
                        onChange={(value) =>
                          handleChange("phone", value, setValues)
                        }
                      />
                      {}
                    </div>
                  </>
                )}
                {stepNumber === 1 && (
                  <>
                    {/* <div className="col-md-12 col-12">
                    <p>
                      <span className="text-justify">Q.1 :</span>
                      <span className="text-justify">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </span>
                    </p>
                  </div>
                  <div className="col-md-12 mb-4 col-12">
                    <ReusableTextField
                      helperText={errmsg.nameErrMsg}
                      type={"text"}
                      label={"Your Answer"}
                      id={"name"}
                      value={values.name}
                      onChange={(value) =>
                        handleChange("name", value, setValues)
                      }
                    />
                  </div>
                  <div className="col-md-12 col-12">
                    <p>
                      <span className="text-justify">Q.1 :</span>
                      <span className="text-justify">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </span>
                    </p>
                  </div>
                  <div className="col-md-12 mb-4 col-12">
                    <ReusableTextField
                      helperText={errmsg.nameErrMsg}
                      type={"text"}
                      label={"Your Answer"}
                      id={"name"}
                      value={values.name}
                      onChange={(value) =>
                        handleChange("name", value, setValues)
                      }
                    />
                  </div>
                  <div className="col-md-12 col-12">
                    <p>
                      <span className="text-justify">Q.1 :</span>
                      <span className="text-justify">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </span>
                    </p>
                  </div>
                  <div className="col-md-12 mb-4 col-12">
                    <ReusableTextField
                      helperText={errmsg.nameErrMsg}
                      type={"text"}
                      label={"Your Answer"}
                      id={"name"}
                      value={values.name}
                      onChange={(value) =>
                        handleChange("name", value, setValues)
                      }
                    />
                  </div>
                  <div className="col-md-12 col-12">
                    <p>
                      <span className="text-justify">Q.1 :</span>
                      <span className="text-justify">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </span>
                    </p>
                  </div>
                  <div className="col-md-12 mb-4 col-12">
                    <ReusableTextField
                      helperText={errmsg.nameErrMsg}
                      type={"text"}
                      label={"Your Answer"}
                      id={"name"}
                      value={values.name}
                      onChange={(value) =>
                        handleChange("name", value, setValues)
                      }
                    />
                  </div> */}
                    {/* <div className="container">
                      <SliderNext />
                    </div> */}
                  </>
                )}
              </div>
            </Box>
          </Typography>
        </CardContent>
        <CardActions className="justify-content-center d-flex px-4 pb-4 pt-0">
          {/* <Button
            className={`text-capitalize button ${
              stepNumber === 0 ? "button__disabled" : ""
            }`}
            size="small"
            disabled={stepNumber === 0 ? true : false}
            onClick={() => {
              if (stepNumber > 0) {
                setStepNumber(stepNumber - 1);
              }
            }}
          >
            Previouse
          </Button> */}
          <Button
            className="button text-center text-capitalize justify-content-center px-3 fw-bold py-2 w-100"
            size="small"
            onClick={() => {
              btnSubmit();
            }}
          >
            Submit
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
